import { createStore } from 'vuex'
import axios from "axios"


async function getResource(endpoint) {
  let baseUrl = "https://eu-central-1.aws.data.mongodb-api.com/app/gallery-rwuxf/endpoint"
  let headers = { "headers": { "accept": "application/json" } }
  let data = null
  await axios.get(baseUrl + endpoint, headers)
    .then(response => {
      data = response.data
    })
    .catch(error => {
      console.log(error)
    }).finally(() => console.log(""))
  return data
}

export default createStore({
  state: {
    drawer: true,
    artDialog: {
      isActive: false,
      data: null,
    },
    yearsDialog: {
      isActive: false,
      selectedIndex: 0,
      years: [],
    },
    images: {
      paintings: null,
      drawnings: null,
      portraits: null,
      graphics: null,
      sculptures: null,
      projects: {
        monuments: null,
        poetry: null,
        recitals: null,
        others: null,
      },
    },
  },
  getters: {
    getArtDialogState: state => state.artDialog,
    getYearsDialogState: state => state.yearsDialog,
    getPaintingsState: state => state.images.paintings,
    getDrawningsState: state => state.images.drawnings,
    getPortraitsState: state => state.images.portraits,
    getGraphicsState: state => state.images.graphics,
    getSculpturesState: state => state.images.sculptures,
    getProjectsState: state => state.images.projects,
    getMonumentsState: state => state.images.projects.monuments,
    getPoetryState: state => state.images.projects.poetry,
    getRecitalsState: state => state.images.projects.recitals,
    getOthersState: state => state.images.projects.others,
  },
  mutations: {
    changeDrawer(state) {
      state.drawer = !state.drawer
    },
    setDrawer(state, value) {
      state.drawer = value
    },
    changeArtDialog(state, isActive) {
      state.artDialog.isActive = isActive
    },
    changeArtDialogItem(state, item) {
      state.artDialog.data = item
    },
    changeYearsDialog(state, isActive) {
      state.yearsDialog.isActive = isActive
    },
    changeYearsDialogItem(state, item) {
      state.yearsDialog.years = item
    },
    changeSelectedYear(state, year) {
      state.yearsDialog.selectedIndex = state.yearsDialog.years.indexOf(year)
    },
    changePaintings(state, data) {
      state.images.paintings = data
    },
    changeDrawnings(state, data) {
      state.images.drawnings = data
    },
    changePortraits(state, data) {
      state.images.portraits = data
    },
    changeGraphics(state, data) {
      state.images.graphics = data
    },
    changeSculptures(state, data) {
      state.images.sculptures = data
    },
    changeProjects(state, data) {
      state.images.projects.monuments = data.filter((item) => item.type === 'Pomniki')
      state.images.projects.poetry = data.filter((item) => item.type === 'Poezja')
      state.images.projects.recitals = data.filter((item) => item.type === 'Recitale')
      state.images.projects.others = data.filter((item) => item.type === 'Pozostałe')
    },
  },
  actions: {
    async fetchPaintings({ commit }, year='2024') {
      commit("changePaintings", await getResource('/paintings/' + year))
    },
    async fetchYears({ commit }) {
      commit("changeYearsDialogItem", await getResource('/paintings/years'))
    },
    async fetchDrawnings({ commit }) {
      commit("changeDrawnings", await getResource('/drawnings'))
    },
    async fetchPortraits({ commit }) {
      commit("changePortraits", await getResource('/portraits'))
    },
    async fetchGraphics({ commit }) {
      commit("changeGraphics", await getResource('/graphics'))
    },
    async fetchSculptures({ commit }) {
      commit("changeSculptures", await getResource('/sculptures'))
    },
    async fetchProjects({ commit, state }) {
      commit("changeProjects", await getResource('/projects'))
    },
    async fetchMonuments({}) {},
    async fetchPoetry({}) {},
    async fetchRecitals({}) {},
    async fetchOthers({}) {},
    changeArtDialogItem({ commit }, item) {
      commit("changeArtDialog", true)
      commit("changeArtDialogItem", item)
    },
  },
  modules: {
  }
})
